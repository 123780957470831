// extracted by mini-css-extract-plugin
export var application = "SingleCaseStudies-module--application--0482b";
export var applicationHeading = "SingleCaseStudies-module--applicationHeading--0ac85";
export var applicationMap = "SingleCaseStudies-module--applicationMap--a37e2";
export var approach = "SingleCaseStudies-module--approach--82405";
export var backgroundCircles = "SingleCaseStudies-module--backgroundCircles--b04d7";
export var banner = "SingleCaseStudies-module--banner--868c1";
export var benefits = "SingleCaseStudies-module--benefits--6dbc6";
export var bottomButton = "SingleCaseStudies-module--bottomButton--22a12";
export var careerBtnApplyNow = "SingleCaseStudies-module--careerBtnApplyNow--e4d9f";
export var careers = "SingleCaseStudies-module--careers--56646";
export var caseChallengeImage = "SingleCaseStudies-module--caseChallengeImage--6edc0";
export var caseTestimonialImage = "SingleCaseStudies-module--caseTestimonialImage--265f9";
export var contact = "SingleCaseStudies-module--contact--85ba1";
export var contactButton = "SingleCaseStudies-module--contactButton--ac650";
export var contactOutlineCircle = "SingleCaseStudies-module--contactOutlineCircle--2dd89";
export var contactOutlineCircleBenfits = "SingleCaseStudies-module--contactOutlineCircleBenfits--ef74c";
export var coreValue = "SingleCaseStudies-module--coreValue--45606";
export var coreValueColumbLeft = "SingleCaseStudies-module--coreValueColumbLeft--a0807";
export var coreValueColumbRight = "SingleCaseStudies-module--coreValueColumbRight--79e34";
export var coreValueItem = "SingleCaseStudies-module--coreValueItem--d5279";
export var coreValueItemBox = "SingleCaseStudies-module--coreValueItemBox--284f1";
export var flowContainer = "SingleCaseStudies-module--flowContainer--51e3b";
export var flowItem = "SingleCaseStudies-module--flowItem--0c1e7";
export var flowItemDay = "SingleCaseStudies-module--flowItemDay--575e1";
export var flowItemDayMobile = "SingleCaseStudies-module--flowItemDayMobile--b0d60";
export var flowItemDayMobileContainer = "SingleCaseStudies-module--flowItemDayMobileContainer--a90c6";
export var flowItemMobile = "SingleCaseStudies-module--flowItemMobile--2ea22";
export var flowLeftContainer = "SingleCaseStudies-module--flowLeftContainer--5e2e3";
export var flowRightContainer = "SingleCaseStudies-module--flowRightContainer--9522a";
export var flowRightContainerMobile = "SingleCaseStudies-module--flowRightContainerMobile--0d587";
export var flowStop = "SingleCaseStudies-module--flowStop--f0444";
export var followUs = "SingleCaseStudies-module--followUs--ffa74";
export var gdnPromotePic = "SingleCaseStudies-module--gdnPromotePic--63d0c";
export var heading = "SingleCaseStudies-module--heading--842a2";
export var heroBackgroundCircleLeft = "SingleCaseStudies-module--heroBackgroundCircleLeft--a0f9d";
export var heroBackgroundInnerCircleGreen = "SingleCaseStudies-module--heroBackgroundInnerCircleGreen--eade2";
export var heroBackgroundInnerCircleLeft = "SingleCaseStudies-module--heroBackgroundInnerCircleLeft--7db14";
export var heroBackgroundInnerCircleLeft2 = "SingleCaseStudies-module--heroBackgroundInnerCircleLeft2--7c8a0";
export var heroBackgroundInnerCircleLeftBenfits = "SingleCaseStudies-module--heroBackgroundInnerCircleLeftBenfits--4314d";
export var heroBackgroundInnerCircleOverflowText = "SingleCaseStudies-module--heroBackgroundInnerCircleOverflowText--3d6f5";
export var heroBackgroundInnerCircleRight = "SingleCaseStudies-module--heroBackgroundInnerCircleRight--29dc3";
export var heroBannerMainContainer = "SingleCaseStudies-module--heroBannerMainContainer--fff82";
export var heroWhiteBlurCirce = "SingleCaseStudies-module--heroWhiteBlurCirce--bcfdd";
export var igImageGroup1 = "SingleCaseStudies-module--igImageGroup1--4f613";
export var igImageGroup1Item = "SingleCaseStudies-module--igImageGroup1Item--831f5";
export var igImageGroup2 = "SingleCaseStudies-module--igImageGroup2--c7641";
export var igImageGroup2Btm = "SingleCaseStudies-module--igImageGroup2Btm--f294e";
export var igImageGroup2BtmItem = "SingleCaseStudies-module--igImageGroup2BtmItem--7ed78";
export var igImageGroup2Item = "SingleCaseStudies-module--igImageGroup2Item--a0b35";
export var inNumberColumbLeft = "SingleCaseStudies-module--inNumberColumbLeft--e2bc7";
export var inNumberColumbRight = "SingleCaseStudies-module--inNumberColumbRight--4cf4d";
export var inNumbersImage = "SingleCaseStudies-module--inNumbersImage--992f6";
export var inNumbersItem = "SingleCaseStudies-module--inNumbersItem--65e4b";
export var innumbers = "SingleCaseStudies-module--innumbers--9ec37";
export var insights = "SingleCaseStudies-module--insights--cab4d";
export var instaContainer = "SingleCaseStudies-module--instaContainer--2d4af";
export var instaLeftBox = "SingleCaseStudies-module--instaLeftBox--832c3";
export var instaRightBox = "SingleCaseStudies-module--instaRightBox--ea03f";
export var introDescriptionButtonSection = "SingleCaseStudies-module--introDescriptionButtonSection--ae172";
export var introImageSection = "SingleCaseStudies-module--introImageSection--f9f5f";
export var introSectionCircleItem = "SingleCaseStudies-module--introSectionCircleItem--164e4";
export var introSectionContainer = "SingleCaseStudies-module--introSectionContainer--76922";
export var introSectionText = "SingleCaseStudies-module--introSectionText--9fdb4";
export var keyTopicsImage = "SingleCaseStudies-module--keyTopicsImage--5136c";
export var knowUs = "SingleCaseStudies-module--knowUs--16785";
export var knowUsImage = "SingleCaseStudies-module--knowUsImage--08625";
export var knowUsText = "SingleCaseStudies-module--knowUsText--cafaf";
export var pillars = "SingleCaseStudies-module--pillars--5c508";
export var reportBtn = "SingleCaseStudies-module--reportBtn--9cbab";
export var reportBtnGerman = "SingleCaseStudies-module--reportBtnGerman--d3214";
export var singleBenefitTextBox = "SingleCaseStudies-module--singleBenefitTextBox--a498b";
export var singleCaseChallenge = "SingleCaseStudies-module--singleCaseChallenge--00485";
export var singleCaseChallengeFirst = "SingleCaseStudies-module--singleCaseChallengeFirst--ec797";
export var singleCaseContentBlock = "SingleCaseStudies-module--singleCaseContentBlock--1290f";
export var singleCaseImageBox = "SingleCaseStudies-module--singleCaseImageBox--191aa";
export var singleCaseImageBoxForth = "SingleCaseStudies-module--singleCaseImageBoxForth--232cc";
export var singleCaseImageBoxSecond = "SingleCaseStudies-module--singleCaseImageBoxSecond--dabc4";
export var singleCaseSolution = "SingleCaseStudies-module--singleCaseSolution--9982d";
export var singleCaseSolutionMobile = "SingleCaseStudies-module--singleCaseSolutionMobile--3c950";
export var singleCaseTestimonial = "SingleCaseStudies-module--singleCaseTestimonial--c621f";
export var singleCaseTestimonialInner = "SingleCaseStudies-module--singleCaseTestimonialInner--18837";
export var singleCaseTestimonialVideo = "SingleCaseStudies-module--singleCaseTestimonialVideo--89d3a";
export var singleCaseTextBox = "SingleCaseStudies-module--singleCaseTextBox--6f888";
export var singleCaseTextBoxLast = "SingleCaseStudies-module--singleCaseTextBoxLast--5e5cb";
export var singleCaseVideoSection = "SingleCaseStudies-module--singleCaseVideoSection--b5466";
export var testimonialBody = "SingleCaseStudies-module--testimonialBody--442e0";
export var testimonialContent = "SingleCaseStudies-module--testimonialContent--85f04";
export var testimonialName = "SingleCaseStudies-module--testimonialName--d163e";
export var testimonialVideo = "SingleCaseStudies-module--testimonialVideo--c9c3d";
export var torchWrapper = "SingleCaseStudies-module--torchWrapper--8be63";
export var verticalLine = "SingleCaseStudies-module--verticalLine--049c1";
export var verticalLineMobile = "SingleCaseStudies-module--verticalLineMobile--81414";
export var yourBenefits = "SingleCaseStudies-module--yourBenefits--3513f";
export var yourBenefitsIcon = "SingleCaseStudies-module--yourBenefitsIcon--cc2c5";
export var yourBenefitsInner = "SingleCaseStudies-module--yourBenefitsInner--a7bfa";
export var yourBenefitsItem = "SingleCaseStudies-module--yourBenefitsItem--b0b8b";